import img_news from "../images/landing/img_news.png"

export interface INew {
    image?: string,
    title: string,
    date: string,
    link: string
}

export const News: INew[] = [{
    image: img_news,
    title: "WECAN Launches New Server for R-Planet Game, \"Ahnangs Invasion\"",
    date: "Apr 28",
    link: "https://wecandev.medium.com/wecan-launches-new-server-for-r-planet-game-ahnangs-invasion-c9deafbed53c"
},{
    title: "WECAN Showcased R-Planet at Web3 Gaming Fest in Porto",
    date: "Mar 30",
    link: "https://wecandev.medium.com/wecan-showcased-r-planet-at-web3-gaming-fest-in-porto-1f62248ed8ad"
},{
    title: "WECAN’s latest venture, Pepper Stake, has been launched",
    date: "Mar 2",
    link: "https://wecandev.medium.com/wecans-latest-venture-pepper-stake-has-been-launched-58f01aafbc3f"
},{
    title: "WECAN became one of the WAX Block Producers",
    date: "Mar 2",
    link: "https://wecandev.medium.com/wecan-became-one-of-the-wax-block-producers-eb82a669ac04"
},{
    title: "There is a War in Ukraine",
    date: "Mar 1",
    link: "https://wecandev.medium.com/there-is-a-war-in-ukraine-f27b9d4cc36"
},];