import React from "react"
import Styles from "./Banner.module.scss"
import imageCloud from '../images/landing/imageCloud.png'
import H1 from "./Ui/H1";

const Banner = () => (
    <div className={Styles.container}>
        <div className={Styles.content}>
            <H1>We <span/></H1>
            <div className={Styles.frame}>
                <img src={imageCloud} alt={"image cloud"}/>
            </div>
        </div>
    </div>
)

export default Banner
