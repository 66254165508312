import React from "react"
import Styles from "./NewTile.module.scss"
import Buttons, {EButtonType} from "./Ui/Buttons";
import newsImage from "../images/landing/img_news.png"
import {INew} from "../date/News";
import H4 from "./Ui/H4";
import Ps from "./Ui/Ps";
import classNames from "classnames";

const NewTile = (
    {
        item
    }: {
        item: INew
    }
) => (
    <div className={classNames(Styles.container, {[Styles.widthTile]: item.image})}>
        <div className={Styles.content}>
            <Ps>
                {item.date}
            </Ps>
            <div className={Styles.name}>
                <H4>
                    {item.title}
                </H4>
            </div>
            <a href={item.link} target={"_blank"}>
                <Buttons text={"LEARN MORE"} type={EButtonType.THIRD} icon={true}/>
            </a>
        </div>
        {item.image &&
        <div className={Styles.newsImage}>
            <img src={newsImage} alt={"news image"}/>
        </div>}
    </div>
)

export default NewTile
