import React, { ReactNode } from 'react';
import Styles from "./H4.module.scss";

export const H4 = (
	{
		children
	}: {
		children: ReactNode
	}
) => {
	return (
		<h4 className={Styles.h4}>
			{children}
		</h4>
	);
};

export default H4;
