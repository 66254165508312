import React from "react"
import Styles from "./OurPartners.module.scss"
import BTCTrading from "../images/landing/partners/img_BTCtradingDefault.png"
import BTCTradingHovered from "../images/landing/partners/img_BTCtradingHovered.png"
import Atomic from "../images/landing/partners/img_partnerAtomicDefault.png"
import AtomicHovered from "../images/landing/partners/img_partnerAtomicHovered.png"
import Wax from "../images/landing/partners/img_partnerWaxDefault.png"
import WaxHovered from "../images/landing/partners/img_partnerWaxHovered.png"
import Horizon from "../images/landing/partners/img_partnerNftHorizonDefault.png"
import HorizonHovered from "../images/landing/partners/img_partnerNftHorizonHovered.png"
import ChainplayHovered from "../images/landing/partners/img_partnerChainplayHovered.png"
import Chainplay from "../images/landing/partners/img_partnerChainplayDefault.png"
import H2 from "./Ui/H2";

const OurPartners = () => (
    <div className={Styles.ourPartner}>
        <H2>Our Partners</H2>
        <div className={Styles.background}>
            {/*<div className={Styles.partner}>*/}
            {/*    <a href={"https://bitcoin-trading.io/"} target={"_blank"}>*/}
            {/*        <img src={BTCTrading} alt={"btc trading"} className={Styles.default}/>*/}
            {/*        <img src={BTCTradingHovered} alt={"btc trading"} className={Styles.hovered}/>*/}
            {/*    </a>*/}
            {/*</div>*/}
            {/*<div className={Styles.partner}>*/}
            {/*    <a href={"https://wax.atomichub.io/"} target={"_blank"}>*/}
            {/*        <img src={Atomic} alt={"atomic trading"} className={Styles.default}/>*/}
            {/*        <img src={AtomicHovered} alt={"atomic trading"} className={Styles.hovered}/>*/}
            {/*    </a>*/}
            {/*</div>*/}
            <div className={Styles.partner}>
                <a href={"https://on.wax.io/wax-io/"} target={"_blank"}>
                    <img src={Wax} alt={"wax trading"} className={Styles.default}/>
                    <img src={WaxHovered} alt={"wax trading"} className={Styles.hovered}/>
                </a>
            </div>
            <div className={Styles.partner}>
                <a href={"https://nfthorizon.io/"} target={"_blank"}>
                    <img src={Horizon} alt={"Horizon Hovered"} className={Styles.default}/>
                    <img src={HorizonHovered} alt={"Horizon Hovered"} className={Styles.hovered}/>
                </a>
            </div>
            {/*<div className={Styles.partner}>*/}
            {/*    <a href={"https://chainplay.gg/games/prospectors/"} target={"_blank"}>*/}
            {/*        <img src={Chainplay} alt={"Horizon Hovered"} className={Styles.default}/>*/}
            {/*        <img src={ChainplayHovered} alt={"Horizon Hovered"} className={Styles.hovered}/>*/}
            {/*    </a>*/}
            {/*</div>*/}
        </div>
    </div>
)

export default OurPartners
