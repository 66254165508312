import React, {useEffect, useState} from "react"
import Styles from "./ProjectScrollList.module.scss"
import {ProjectsInfo} from "../date/ProjectInfo";
import classNames from "classnames";
import {getOffset, getDOM} from "../helper/getDOM";
import ProjectList from "./ProjectList";

const ProjectScrollList = () => {
    const [scrollY, setScrollY] = useState<number>(0);

    let windowsHeight = 0;
    const blockHeight = 800;
    const topOffsetInCss = windowsHeight / 2;

    const startSlide = typeof window === 'undefined' || !window.document
        ? 0
        : getOffset(document.getElementById("future_items")).top - topOffsetInCss

    useEffect(() => {
        window.addEventListener('scroll', scrollEvent)
        return () => {
            window.removeEventListener('scroll', scrollEvent)
        }
    })

    function scrollEvent(e: Event) {
        setScrollY(getDOM(e).sy)
        windowsHeight = window.innerHeight;
    }

    function getClass(index: number) {
        if (startSlide - blockHeight / 2 + blockHeight * (index + 1) > scrollY && startSlide - blockHeight / 2 + blockHeight * index < scrollY) {
            return Styles.show
        }
    }

    return (
        <div className={Styles.container} id={"future_items"}>
            <ProjectList image={true}/>
            <div className={classNames(Styles.rightWrap, Styles.fixed)}>
                {startSlide < scrollY ?
                    startSlide + blockHeight * (ProjectsInfo.length - 1) > scrollY ?
                        ProjectsInfo.map((project, index) =>
                            <div className={classNames(Styles.image)} key={index}>
                                <img src={project.image} alt={"rplanet project"}
                                     className={getClass(index)}/>
                            </div>
                        )
                        :
                        <img src={ProjectsInfo[ProjectsInfo.length - 1].image} alt={"rplanet project"}/>
                    :
                    <img src={ProjectsInfo[0].image} alt={"rplanet project"}/>}
            </div>
        </div>
    )
}

export default ProjectScrollList
