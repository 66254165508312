import React, {useState} from "react"
import Styles from "./AboutTile.module.scss"
import H3 from "./Ui/H3";
import Icon, {EIconSize, EIconType} from "./Ui/Icon";
import classNames from "classnames";
import Pb from "./Ui/Pb";

const AboutTile = (
    {
        tile,
        text
    }: {
        tile: string,
        text: string
    }) => {
    const [showTile, setShowTile] = useState(false);

    return (
        <div className={classNames(Styles.container, {[Styles.show]: showTile})} onClick={() => setShowTile(!showTile)}>
            <div className={Styles.text}>
                <H3>{tile}</H3>
            </div>
            <div className={Styles.hoverText}>
                <Pb>
                    {text}
                </Pb>
            </div>
            <div className={Styles.plus}>
                <Icon type={EIconType.MORE} size={EIconSize.BIG}/>
            </div>
        </div>
    )
}

export default AboutTile
