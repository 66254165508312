import React, {useEffect, useState} from "react";
import Layout from "../components/Layout";
import SEO from "../components/seo";
import Styles from "./index.module.scss"
import StylesLayout from "../components/Layout.module.scss"
import "../styles/global.scss"
import Banner from "../components/Banner";
import AboutTile from "../components/AboutTile";
import H3 from "../components/Ui/H3";
import Buttons, {EButtonType} from "../components/Ui/Buttons";
import NewTile from "../components/NewTile";
import {L} from "../l10n/L10n";
import {News} from "../date/News";
import ProjectScrollList from "../components/ProjectScrollList";
import TeamList from "../components/TeamList";
import {getDOM, getOffset} from "../helper/getDOM";
import OurPartners from "../components/OurPartners";
import {PagePath} from "../helper/PageHelper";

const IndexPage = () => {
    const [darkThem, setDarkThem] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', scrollEvent)
        return () => {
            window.removeEventListener('scroll', scrollEvent)
        }
    })

    function scrollEvent(e: Event) {
        let windowsHeight = window.innerHeight;
        let startThem = getOffset(document.getElementById("teams")).top - windowsHeight / 2
        let endThem = getOffset(document.getElementById("news")).top - windowsHeight / 2
        let scrollPosition = getDOM(e).sy;
        scrollPosition > startThem && scrollPosition < endThem ? setDarkThem(true) : setDarkThem(false)
    }
    return (
        <Layout lightThem={darkThem}>
            <SEO title="Full stack game development"
                 description="WECAN distributed ledger game development team leverages emerging technology to create gaming applications utilizing EOSIo software."/>
            <div className={Styles.backGround}/>
            <div className={StylesLayout.content}>
                <Banner/>
            </div>
            <div className={StylesLayout.content}>
                <div className={Styles.aboutBlock}>
                    <AboutTile tile={L.about.tile} text={L.about.text_hover}/>
                    <AboutTile tile={L.about.tile_2} text={L.about.text_2_hover}/>
                    <AboutTile tile={L.about.tile_3} text={L.about.text_3_hover}/>
                </div>
                <div className={Styles.cardAboutUs}>
                    <H3>{L.about.card_about}</H3>
                    <a href={PagePath.ABOUT}>
                        <Buttons text={'Learn More'} type={EButtonType.PRIMARY}/>
                    </a>
                </div>
                <ProjectScrollList/>
                <div id={'teams'}>
                    <TeamList/>
                </div>
                <div className={Styles.news} id={'news'}>
                    {News.map(item => <NewTile item={item} key={item.title}/>)}
                </div>
                <OurPartners/>
            </div>
        </Layout>
    )
};

export default IndexPage;
